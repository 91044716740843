export default defineStore('app', () => {
  const router = useRouter()
  const isSidebarOpen = ref(false)

  if (import.meta.client) {
    const { width } = useWindowSize()

    router.afterEach(() => {
      isSidebarOpen.value = false
    })

    watch(width, () => {
      isSidebarOpen.value = false
    })
  }

  return {
    isSidebarOpen,
  }
})
